/* Container for the FAQ section */
.faq-section {
    padding: 40px 20px;
    background-color: #f9f9f9;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;

  }
  
  /* Title style */
  .faq-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  /* List of FAQ items */
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  /* Each FAQ item style */
  .faq-item {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Style for active FAQ item */
  .faq-item.active {
    background-color: #e9ecef;
  }
  
  /* Question style */
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Toggle icon style */
  .toggle-icon {
    font-size: 20px;
    color: #007bff;
  }
  
  /* Answer style */
  .faq-answer {
    font-size: 16px;
    color: #555;
    padding-top: 10px;
  }
  