/* Footer.css */
.Footer {
  background-color: #1a1a1a;
  color: #ddd;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box; /* Includes padding in width calculation */
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
}

.footer-section {
  flex: 1 1 200px;
  margin: 10px 20px;
}

.footer-section h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
}

.footer-section p,
.footer-section ul {
  font-size: 0.9rem;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 8px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #ddd;
  transition: color 0.3s;
}

.footer-section ul li a:hover {
  color: #fff;
}

.social-media .social-icons {
  display: flex;
  gap: 15px;
}

.social-media .social-icons a {
  color: #ddd;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social-media .social-icons a:hover {
  color: #fff;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 0.8rem;
  color: #777;
  width: 100%;
  border-top: 1px solid #333;
  padding-top: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 20px 0;
    text-align: center;
  }

  .footer-section ul li {
    display: inline-block;
    margin: 0 10px;
  }
}
