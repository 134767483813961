/* App.css */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures that the app takes up the full height of the viewport */
  overflow-x: hidden; /* Prevents horizontal overflow */
}

.Content {
  flex: 1; /* Allows content to expand and fill the available space between navbar and footer */
  padding: 20px;
  background-color: #f8f9fa; /* Light background color */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.main-content {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.main-content h1 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 20px;
}

.main-content p {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }

  .main-content h1 {
    font-size: 1.5rem;
  }
}


