/* OurCustomers.css */
.OurCustomers {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background for contrast */
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .customer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Space between logos */
  }
  
  .customer-item {
    flex: 1 1 150px; /* Flex properties for responsive design */
    max-width: 200px; /* Limit the size of each logo */
    margin:auto auto;
  }
  
  .customer-item img {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
    display: block;
  }
  