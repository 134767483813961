/* Navbar.css */
.Navbar {
  width: 100%;
  height: 8vh;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
}

.LeftSection {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.RightSection {
  display: flex;
  align-items: center;
}

.shopping-basket-icon {
  font-size: 1.5rem;
  color: #ddd;
  margin-right: 20px;
  cursor: pointer;
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 20px;
  border: 2px solid #fff;
}

.dropdown-icon {
  color: #ddd;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #2a2a2a;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 150px;
}

.dropdown-link {
  color: #ddd;
  padding: 8px 10px;
  text-decoration: none;
  transition: background 0.3s ease;
}

.dropdown-link:hover {
  background-color: #444;
}

.menu-icon {
  display: none;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
}

/* Side Drawer */
.side-drawer {
  position: fixed;
  top: 0;
  left: -100%;
  width: 75%;
  height: 100%;
  background-color: #1a1a1a;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 999;
}

.side-drawer.open {
  left: 0;
}

.side-drawer-header {
  display: flex;
  justify-content: space-between;
  height: 8vh;
  align-items: center;
  padding-left:20px;
  padding-right: 20px;
  background-color: #000;
}

.drawer-company-logo {
  width: 40px;
  height: auto;
}

.close-drawer-icon {
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.side-drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.drawer-link {
  color: #ddd;
  padding: 15px 0;
  text-decoration: none;
  transition: background 0.3s ease;
}

.drawer-link:hover {
  background-color: #333;
}

.drawer-profile {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.drawer-profile-photo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  border: 2px solid #fff;
}

.drawer-dropdown-icon {
  color: #ddd;
}

.drawer-shopping-basket-icon {
  font-size: 1.5rem;
  color: #ddd;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .RightSection {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}
