/* AppLinks.css */
.AppLinks {
    text-align: center;
    margin: 40px 0;
    padding: 20px;
    background-color: #f8f9fa; /* Light background color */
    border-radius: 8px;
  }
  
  .AppLinks h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .store-links {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .store-logo {
    width: 150px; /* Adjust size */
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .store-logo:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  