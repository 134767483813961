/* PricingSection.css */
.PricingSection {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light background to contrast with cards */
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  