/* PlanCard.css */
.PlanCard {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    margin: 15px;
    flex: 1 1 300px; /* Flex grow/shrink properties for responsive layout */
    max-width: 350px; /* Limit the card width */
    max-height: 350px;
  }
  
  .plan-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
    height: 7%;
  }
  
  .plan-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    height: 45%;
  }
  
  .plan-price {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .select-plan-button {
    background-color: #007bff; /* Bootstrap primary color for a professional look */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .select-plan-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .select-plan-button:focus {
    outline: none;
  }
  